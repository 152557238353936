import React from 'react'
import { useEffect } from 'react';
import "../pagestyle/portfoliopage.css"
import Footer from '../components/UI/Footer'
import ProjectCard from './ProjectCard';
import img1 from '../images/it.jpg'
import img2 from '../images/cload.jpg'
import img3 from '../images/web.jpg'
import img4 from '../images/software.jpg'
import img5 from '../images/cyber.jpg'
import img6 from '../images/seo.jpg'
import img7 from '../images/socail.jpg'
import img8 from '../images/content.jpg'
import img9 from '../images/email.jpg'
import img10 from '../images/video pro.jpg'
import img11 from '../images/animation.jpg'
import img12 from '../images/live.jpg'
import img13 from '../images/vr.jpg'

const projects = [
  {
    name: 'IT Consultation and Support',
    detail: 'Our IT experts provide consultation and support to keep your systems efficient and secure.',
    image: img1, // Replace with the actual image URL
  },
  {
    name: 'Cloud Solutions',
    detail: 'Optimize your business with cloud solutions for data storage, scalability, and accessibility.',
    image: img2,
  },
  {
    name: 'Web Development',
    detail: 'We create responsive and user-friendly websites tailored to your specific needs.',
    image: img3,
  },
  {
    name: 'Software Development',
    detail: 'Custom software solutions to streamline your business processes and enhance productivity.',
    image: img4,
  },
  {
    name: 'Cybersecurity',
    detail: 'Protect your data and network with our comprehensive cybersecurity services and solutions.',
    image: img5,
  },
  {
    name: 'Search Engine Optimization (SEO)',
    detail: 'Improve your website\'s visibility on search engines and drive organic traffic.',
    image: img6
  },
  {
    name: 'Social Media Marketing',
    detail: 'Engage your audience and build brand awareness through strategic social media campaigns.',
  image: img7
  },
  {
    name: 'Content Marketing',
    detail: 'Create valuable and relevant content to attract and retain your target audience.',
    image: img8,
  },
  {
    name: 'Email Marketing',
    detail: 'Reach your customers directly with personalized email marketing campaigns.',
    image: img9,
  },
  
  {
    name: 'Video Production Services',
    detail: 'Professional video production services to bring your ideas to life.',
    image: img10
  },
  {
    name: 'Animation and Motion Graphics',
    detail: 'Create visually appealing animations and motion graphics for various media.',
    image: img11
  },
  {
    name: 'Live Streaming and Webinars',
    detail: 'Host live events and webinars to connect with your audience in real-time.',
    image: img12
  },
  {
    name: 'Virtual Reality (VR) and Augmented Reality (AR)',
    detail: 'Immerse your audience in virtual and augmented reality experiences.',
    image:img13
  },
];


const Portfoliopage = ({theme, toggleTheme}) => {
  useEffect(()=>{
    window.scrollTo(0,0);
  });
  return (
 <>
      <div className="portfolio">
      <h2>Our Portfolio</h2>
      <div className="project-cards">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
      <Footer/>
 </>
  )
}

export default Portfoliopage