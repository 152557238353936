import React from 'react'
import '../../styles/footer.css'

const quickLinks01=[
  {
path:'#',
display:'Marketing'
  },
  {
    path:'#',
    display:'Analytics'
      },
      {
        path:'#',
        display:'Commerce'
          },
]
const quickLinks02=[
  {
path:'#',
display:'Pricing'
  },
  {
    path:'#',
    display:'Documentation'
      },
      {
        path:'#',
        display:'Guide'
          },
]
const quickLinks03=[
  {
path:'#',
display:'Marketing'
  },
  {
    path:'#',
    display:'Analytics'
      },
      {
        path:'#',
        display:'Commerce'
          },
]
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__logo">

          <h2>SkyTouchMarketing</h2>
            <p className='description'>Grow with us</p>
            <p className="small_text description">CEO: Ameer Hamza</p>
            <p className="small_text description">Address: Office 4-13 Plot 224-1,Maryam Ahmad building Al Barsha Dubai U.A.E</p>
            <p className="small_text description">Tel: <a href="tel:+971 (0)43 931191">+971 (0)43 931191</a></p>
            <p className="small_text description">Mobile: <a href="tel:+971 050 694 3317">+971 050 694 3317</a></p>
            <p className="small_text description">Email: <a href="mailto:info@skytouchmarketing.com">info@skytouchmarketing.com</a></p>

          </div>
          <div className="footer__quick-links">
            <div className="quick__links-title">Solutions</div>
            <ul className="quick__links">
              {quickLinks01.map((item, index)=>(
<li className="quick__link-item" key={index}>
  <a href={item.path}>{item.display}</a>
</li>
              )) }
            </ul>
          </div>
           <div className="footer__quick-links"> 
            <div className="quick__links-title">Support</div>
            <ul className="quick__links">
              {quickLinks02.map((item, index)=>(
<li className="quick__link-item" key={index}>
  <a href={item.path}>{item.display}</a>
</li>
              )) }
            </ul>
          </div> 
           <div className="footer__quick-links">
            <div className="quick__links-title">Company</div>
            <ul className="quick__links">
              {quickLinks03.map((item, index)=>(
<li className="quick__link-item" key={index}>
  <a href={item.path}>{item.display}</a>
</li>
              )) }
            </ul>
          </div> 

        </div>
        < p className="copyright">&copy; 2023 SkyTouch Marketing. All Rights Reserved.</p>
      </div>
    </footer>
    
    
    
  )
}

export default Footer