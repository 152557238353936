import React from 'react'
import "../pagestyle/aboutpage.css"
import Footer from '../components/UI/Footer'
import Aboutpageimg1 from '../images/2999956-removebg-preview.png'
import Aboutpageimg2 from '../images/seo-optimization-3d-render-cartoon-illustration-removebg-preview.png'
import Aboutpageimg3 from '../images/53922-removebg-preview.png'
import Aboutpageimg4 from '../images/pquy_9o2w_220905-removebg-preview.png'
import { useEffect } from 'react'
const Aboutpage = ({theme, toggleTheme}) => {
 
    useEffect(()=>{
        window.scrollTo(0,0);
      });
 return (
<>
    <div className="container">
        <section className="story">
         <div className="story-content">
            <h2>Our Story</h2>
            <p>At <span className="company-name">SkyTouch Marketing</span>, we are driven by a passion for technology, creativity, and innovation. We understand that in today's fast-paced digital world, businesses need comprehensive solutions to succeed. That's why we offer a unique blend of tech services, online marketing expertise, and video production excellence to help our clients thrive in the digital landscape.</p>
         </div>
        <img src={Aboutpageimg1} alt="" />
        </section>
        <section className="mission">
            <div className="mission-content">
            <h2>Our Mission</h2>
            <p>Our mission is simple: to empower businesses of all sizes with the tools, strategies, and creative content they need to achieve their goals and stand out in the digital marketplace. We believe that technology should be harnessed for the betterment of businesses, and we're dedicated to making that a reality for our clients.</p>
            </div>
            <img src={Aboutpageimg2} alt="" />
        </section>
        <section className="sets-apart">
            <div className="set-apart-content">
            <h2>What Sets Us Apart</h2>
            <ul>
                <li><strong>Expertise:</strong> Our team consists of seasoned professionals with extensive experience in their respective fields. Whether it's tech support, online marketing, or video production, we have the skills and knowledge to deliver outstanding results.</li>
                <li><strong>Innovation:</strong> We are constantly pushing the boundaries of what's possible in technology and creativity. Our innovative solutions are tailored to your unique needs, helping you stay ahead of the competition.</li>
                <li><strong>Client-Centric Approach:</strong> At <span className="company-name">SkyTouch Marketing</span>, we prioritize our clients' success above all else. We take the time to understand your business, your goals, and your challenges to deliver personalized solutions that drive real results.</li>
            </ul>
            </div>
            <img src={Aboutpageimg3} alt="" />
        </section>
     
        <section className="commitment">
            <h2>Our Commitment</h2>
            <p>We are committed to excellence in everything we do. When you partner with <span className="company-name">SkyTouch Marketing</span>, you're not just getting services; you're getting a dedicated team that's invested in your success. We measure our success by your success.</p>
            <img src={Aboutpageimg4} alt="" />
        </section>
        <section className="contact">
            <h2>Get in Touch</h2>
            <p>Ready to take your business to the next level? Contact us today to discuss how <span className="company-name">SkyTouch Marketing</span> can help you achieve your digital goals. We look forward to working with you.</p>
        </section>
    </div>
      <Footer/>
 </>
  )
}

export default Aboutpage