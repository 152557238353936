import React from 'react'
import '../../styles/testimonials.css'
import ava01 from '../../images/ava-1.jpg'
import Slider from 'react-slick'
import ava02 from '../../images/ava-2.jpg'
import ava03 from '../../images/ava-3.jpg'

const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay:true,
    autoplaySpeed:3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide:true
  };

const Testimonials = () => {
  return (
<section>
<div className='container'>
    <div className="slider__content-top">
        <h6 className="subtitle">Testimonials</h6>
        <h2>
            Trusted by more than{" "}
            <span className="highlight">5,000 customers</span>
        </h2>
            </div>
    <div className="slider__wrapper">
    <Slider{...settings}>
        <div className="slider__item">
            <p className="description">Working with SkyTouch Marketing has been a game-changer for our business. Their expertise and dedication have significantly boosted our marketing efforts.</p>
        <div className="customer__details">
            <div className="customer__img">
                <img src={ava01} alt="" />
            </div>
            <div>
                <h5 className="customer__name">larry dane</h5>
          <p className="description">CEO WorkCreation Ltd</p>
            </div>
        </div>
        
        </div>

        <div className="slider__item">
            <p className="description">SkyTouch Marketing stands out as an invaluable ally, continually exceeding expectations with their strategic prowess and responsive support.</p>
        
        <div className="customer__details">
            <div className="customer__img">
                <img src={ava02} alt="" />
            </div>
            <div>
                <h5 className="customer__name">Anney Martin</h5>
          <p className="description">Software Developer</p>
            </div>
        </div>
        
        </div>

        <div className="slider__item">
            <p className="description">"SkyTouch marketing is not just a workplace; it's an innovation hub that fosters creativity and allows us to push design boundaries."</p>
        <div className="customer__details">
            <div className="customer__img">
                <img src={ava03} alt="" />
            </div>
            <div>
                <h5 className="customer__name">William copper</h5>
          <p className="description">Sr.product designer</p>
            </div>
        </div>
        
        </div>
    </Slider>
    </div>
</div>
</section>
  )
}

export default Testimonials