import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import serviceImg1 from '../images/pexels-mikhail-fesenko-9553909.jpg'
// import serviceImg2 from '../images/pexels-visual-tag-mx-5361086.jpg'
// import serviceImg3 from '../images/pexels-pixabay-257904.jpg'

import "../pagestyle/servicespage.css"
import Footer from '../components/UI/Footer'
const Servicespage = ({theme ,toggleTheme}) => {
  useEffect(()=>{
    window.scrollTo(0,0);
  });
  return (
 <>
      <div className="content-1">
      <h1>We Make Services Better</h1>
      <p>"Discover our range of expert services.Choose us for top-notch solutions.</p>
     <div className="services-card-container-1">
<h2>Tech<span>Services</span></h2>
       <div className="card">
  
    <ul>
    <li>IT Consultation and Support</li>
    <li>Cloud Solutions</li>
    <li>Web Development</li>
    <li>Software Development</li>
    <li>Cybersecurity</li>
  </ul>
  <Link to="/Card" >
  <button type='submit'className="secondary__btn">View Pricing</button>
  </Link>

    </div>       
     </div>
    
     <div className="services-card-container-1">
       <div className="card">

    <ul>
    <li>Search Engine Optimization (SEO)</li>
    <li>Social Media Marketing</li>
    <li>Content Marketing</li>
    <li>Email Marketing</li>
    <li>Video production</li>
  </ul>
  <Link to="/Card" >
  <button type='submit'className="secondary__btn">View Pricing</button>
  </Link>    </div>       
    <h2>Marketing<span>Services</span></h2>

     </div>
     <div className="services-card-container-1">
     <h2>VideoProduction<span>Services</span></h2>

       <div className="card">

    <ul>
    <li>Animation and Motion Graphics</li>
    <li>Live Streaming and Webinars</li>
    <li>Video Marketing</li>
    <li>Virtual Reality (VR) and Augmented Reality (AR)</li>
 
  </ul>  
  <Link to="/Card" >
  <button type='submit'className="secondary__btn">View Pricing</button>
  </Link>
    </div>       

     </div>
    </div>

          
 

      <Footer/>

 </>
  )
}

export default Servicespage